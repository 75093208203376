import React, { useEffect, useState } from "react";
import fb, { getDb } from "../firebase";
import { getDatabase, ref, onValue, update } from "firebase/database";
import { Routes } from "../Constants";
import Live from "./Live";
import { getAuth, GoogleAuthProvider, onAuthStateChanged, signInWithPopup } from "firebase/auth";
import { Alert, Button } from "react-bootstrap";
import { GlobalSettings, LiveStats } from "../../../Widget/src/interfaces/UserPresence";
import StatusButton, { Status } from "./StatusButton";
import Modal from "react-bootstrap/Modal";
import { Formik, Field, Form } from "formik";
import ViewDropdown from "./ViewDropdown";

interface DashboardProps {
	path: string;
}

const Dashboard: React.FC<DashboardProps> = ({ path }) => {
	const [showNav, setShowNav] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [allow, setAllow] = useState<boolean>();
	const [settings, setSettings] = useState<GlobalSettings>();
	const [liveStats, setLiveStats] = useState<LiveStats>();
	const [syncLive, setSyncLive] = useState(true);

	const auth = getAuth();
	const db = getDb(path === Routes.dashboardStaging);

	useEffect(() => {
		onAuthStateChanged(auth, (user) => {
			if (!!user) {
				if ((user?.email ?? "").endsWith("brushfire.com")) {
					setAllow(true);
				} else {
					setAllow(false);
				}
			} else {
				signInWithGoogle();
			}
		});
	}, []);

	useEffect(() => {
		const settingsRef = ref(db, "/settings");
		return onValue(settingsRef, (snapshot) => {
			if (snapshot.exists()) {
				const globalSettings = snapshot.val() as GlobalSettings;
				if (globalSettings) {
					setSettings(globalSettings);
				} else {
					//The settings are gone? This should never happen, so throw an error
				}
			} else {
				//The settings are gone? This should never happen, so throw an error
			}
		});
	}, []);

	useEffect(() => {
		const liveRef = ref(db, "/live");
		if (syncLive) {
			return onValue(liveRef, (snapshot) => {
				if (snapshot.exists()) {
					const liveStats = snapshot.val() as LiveStats;
					if (liveStats) {
						setLiveStats(liveStats);
					} else {
						//The settings are gone? This should never happen, so throw an error
					}
				} else {
					//The settings are gone? This should never happen, so throw an error
				}
			});
		}
	}, [syncLive]);

	const signInWithGoogle = () => {
		const provider = new GoogleAuthProvider();
		signInWithPopup(auth, provider);
	};

	const onClients = Object.keys(settings?.clients ?? {}).filter((clientKey) => settings?.clients[clientKey].on);

	if (allow === undefined) {
		return null;
	}

	if (!allow) {
		return (
			<div className="">
				<div className="well well-xs absolute-center">
					<div className="well-content ta-center">
						<h1>BRUSHFIRE Q</h1>
						{(auth.currentUser?.uid !== undefined ||
							(!!auth.currentUser && !auth.currentUser.email?.endsWith("brushfire.com"))) && (
							<Alert variant="danger">Invalid account</Alert>
						)}

						<Button variant="primary" onClick={signInWithGoogle} className="d-block m-top-medium w-100">
							Sign In
						</Button>
					</div>
				</div>
			</div>
		);
	}

	return (
		<div className={`page queue-dashboard ${showNav ? "show-nav" : ""}`}>
			<header>
				{/* <div
					className="hamburger"
					onClick={() => {
						setShowNav(!showNav);
					}}
				>
					<i className="fal fa-bars"></i>
				</div> */}
				<img
					alt="Brushfire Logo"
					className="queue-dashboard-logo"
					src="https://brushfirecontent.blob.core.windows.net/0000/Media/brushfire-logo-bw.png"
				/>
			</header>

			<div className="header-right">
				<ViewDropdown />
				<div className="avatar">
					<img src={auth.currentUser?.photoURL ?? ""} alt={auth.currentUser?.displayName ?? "Avatar"} />
				</div>
			</div>
			{/* <nav className={`nav ${showNav ? "active" : ""}`}>
				<ul>
					<li>
						<NavLink
							to={Routes.dashboardLive}
							onClick={() => {
								if (showNav) {
									setShowNav(false);
								}
							}}
						>
							{({ isActive }) => <span className={isActive ? `active` : undefined}>Live</span>}
						</NavLink>
					</li>
					<li>
						<NavLink
							to={Routes.dashboardClients}
							onClick={() => {
								if (showNav) {
									setShowNav(false);
								}
							}}
						>
							{({ isActive }) => <span className={isActive ? `active` : undefined}>Clients</span>}
						</NavLink>
					</li>
					<li>
						<NavLink
							to={Routes.dashboardSettings}
							onClick={() => {
								if (showNav) {
									setShowNav(false);
								}
							}}
						>
							{({ isActive }) => <span className={isActive ? `active` : undefined}>Settings</span>}
						</NavLink>
					</li>
				</ul>
			</nav> */}
			<section className={`page-body ${showNav ? "nav-active" : ""}`}>
				{path === Routes.dashboardSettings && (
					<div className="row">
						<div className={`col-6 ta-left`}>
							<StatusButton
								text={
									settings?.queueState === 1
										? `Auto: ${onClients.length > 0 ? "On" : "Off"}`
										: settings?.queueState === 2
										? "Forced: On"
										: "Off"
								}
								status={
									settings?.queueState === 1
										? onClients.length > 0
											? Status.on
											: Status.waiting
										: settings?.queueState === 2
										? Status.on
										: Status.off
								}
								onClick={() => {
									setShowModal(true);
								}}
							/>
						</div>
						<div className={`col-6 ta-right`}>
							<button
								className="btn btn-primary"
								onClick={() => {
									setSyncLive(!syncLive);
								}}
							>
								{syncLive ? "Stop" : "Start"}
							</button>
						</div>
					</div>
				)}
				<Live settings={settings} liveStats={liveStats} setShowModal={setShowModal} path={path} />
				{/* {path === Routes.dashboardLive && }
				{path === Routes.dashboardClients && <Clients settings={settings} path={path} liveStats={liveStats} />}
				{path === Routes.dashboardSettings && <Settings settings={settings} liveStats={liveStats} />} */}
			</section>
			<Modal
				show={!!showModal}
				onHide={() => {
					setShowModal(false);
				}}
				centered
			>
				{!!showModal && (
					<Formik
						initialValues={{
							status: settings?.maintenance.on
								? "maintenance"
								: settings?.queueState === 1
								? settings.paused
									? "auto-paused"
									: "auto"
								: settings?.queueState === 2
								? settings.paused
									? "on-paused"
									: "on"
								: "off",
							heading: settings?.heading ?? "",
							body: settings?.body ?? "",
							maintenanceHeading: settings?.maintenance.heading ?? "",
							maintenanceBody: settings?.maintenance.body ?? "",
							maxLetIn: settings?.maxLetIn,
							internetLimit: settings?.internetLimit,
							clientQueuePercent: (settings?.clientQueuePercent ?? 0) * 100,
						}}
						onSubmit={async (values) => {
							const settingsRef = ref(db, `/settings`);
							update(settingsRef, {
								queueState:
									values.status.includes("on") || values.status === "maintenance"
										? 2
										: values.status.includes("auto")
										? 1
										: 0,
								heading: values.heading,
								body: values.body,
								paused: values.status.includes("paused"),
								maintenance: {
									...settings?.maintenance,
									heading: values.maintenanceHeading,
									body: values.maintenanceBody,
									on: values.status === "maintenance",
								},
								maxLetIn: settings?.maxLetIn,
								internetLimit: values.internetLimit,
								clientQueuePercent: !!values?.clientQueuePercent
									? values.clientQueuePercent / 100
									: undefined,
							})
								.then(() => {
									setShowModal(false);
								})
								.catch((err) => {
									console.log(err);
									alert("Something went wrong. Check the console for more info.");
								});
						}}
					>
						{({ values, isSubmitting }) => (
							<Form>
								<Modal.Header>
									<Modal.Title>Queue Settings</Modal.Title>
								</Modal.Header>
								<Modal.Body>
									<div role="group" aria-labelledby="my-radio-group">
										<fieldset className="field">
											<label className="d-block">
												<Field type="radio" name="status" value="off" className="m-right" />
												<div
													className="well queue-status-off"
													style={{
														width: "calc(100% - 23px",
														display: "inline-block",
														verticalAlign: "middle",
														marginTop: 0,
													}}
												>
													<div className="p">
														<h4>
															<i className="fas fa-fw m-right fa-stop"></i>
															Off
														</h4>
														<h6 style={{ marginLeft: "35px" }}>No queue protection</h6>
													</div>
												</div>
											</label>
											<label className="d-block">
												<Field type="radio" name="status" value="on" className="m-right" />
												<div
													className="well queue-status-on"
													style={{
														width: "calc(100% - 23px",
														display: "inline-block",
														verticalAlign: "middle",
														marginTop: 0,
													}}
												>
													<div className="p">
														<h4>
															<i className="fas fa-fw m-right fa-play"></i>
															On
														</h4>
														<h6 style={{ marginLeft: "35px" }}>
															Everyone passes through queue
														</h6>
													</div>
												</div>
											</label>
											<label className="d-block">
												<Field
													type="radio"
													name="status"
													value="on-paused"
													className="m-right"
												/>
												<div
													className="well queue-status-on paused"
													style={{
														width: "calc(100% - 23px",
														display: "inline-block",
														verticalAlign: "middle",
														marginTop: 0,
													}}
												>
													<div className="p">
														<h4>
															<i className="fas fa-fw m-right fa-pause"></i>
															On + Paused
														</h4>
														<h6 style={{ marginLeft: "35px" }}>Everyone held in queue</h6>
													</div>
												</div>
											</label>
											<label className="d-block">
												<Field type="radio" name="status" value="auto" className="m-right" />
												<div
													className="well queue-status-auto"
													style={{
														width: "calc(100% - 23px",
														display: "inline-block",
														verticalAlign: "middle",
														marginTop: 0,
													}}
												>
													<div className="p">
														<h4>
															<i className="fas fa-fw m-right fa-play"></i>
															Auto
														</h4>
														<h6 style={{ marginLeft: "35px" }}>
															Using logic to selectively send to queue
														</h6>
													</div>
												</div>
											</label>
											<label className="d-block">
												<Field
													type="radio"
													name="status"
													value="auto-paused"
													className="m-right"
												/>
												<div
													className="well queue-status-auto"
													style={{
														width: "calc(100% - 23px",
														display: "inline-block",
														verticalAlign: "middle",
														marginTop: 0,
													}}
												>
													<div className="p">
														<h4>
															<i className="fas fa-fw m-right fa-pause"></i>
															Auto + Paused
														</h4>
														<h6 style={{ marginLeft: "35px" }}>
															Those sent to queue are held
														</h6>
													</div>
												</div>
											</label>
											<label className="d-block">
												<Field
													type="radio"
													name="status"
													value="maintenance"
													className="m-right"
												/>
												<div
													className="well queue-status-maintenance"
													style={{
														width: "calc(100% - 23px",
														display: "inline-block",
														verticalAlign: "middle",
														marginTop: 0,
													}}
												>
													<div className="p">
														<h4>
															<i className="fas fa-fw m-right fa-wrench"></i>
															Maintenance + Paused
														</h4>
														<h6 style={{ marginLeft: "35px" }}>
															Everyone held on maintenance screen
														</h6>
													</div>
												</div>
											</label>
										</fieldset>
										{values.status === "maintenance" ? (
											<>
												<div className="field">
													<label htmlFor="maintenanceHeading">Maintenance Heading</label>
													<Field
														placeholder="Message heading"
														name="maintenanceHeading"
														className="d-block w-100 m-top"
													/>
												</div>
												<div className="field">
													<label htmlFor="maintenanceBody">Maintenance Body</label>
													<Field
														placeholder="Message body"
														as="textarea"
														name="maintenanceBody"
														className="d-block w-100 m-top"
													/>
												</div>
											</>
										) : (
											<>
												<div className="field">
													<label htmlFor="message">Custom Queue Heading</label>
													<Field
														placeholder="Message heading"
														name="heading"
														className="d-block w-100 m-top"
													/>
												</div>
												<div className="field">
													<label htmlFor="message">Custom Queue Message</label>
													<Field
														placeholder="Message body"
														as="textarea"
														name="body"
														className="d-block w-100 m-top"
													/>
												</div>
												<div className="field">
													<label htmlFor="message">Max Number Of Users To Let In</label>
													<Field
														placeholder="500"
														name="maxLetIn"
														type="number"
														className="d-block w-100 m-top"
													/>
												</div>
												<div className="field">
													<label htmlFor="message">Max Number Of Internet Users</label>
													<Field
														placeholder="500"
														name="internetLimit"
														type="number"
														className="d-block w-100 m-top"
													/>
												</div>
												{(values?.status === "auto" || values.status === "auto-paused") && (
													<div className="field">
														<label htmlFor="message">
															Percentage of Users Allowed Per Client
														</label>
														<Field
															placeholder="10"
															name="clientQueuePercent"
															type="number"
															max="100"
															className="d-block w-100 m-top"
														/>
													</div>
												)}
											</>
										)}
									</div>
								</Modal.Body>
								<Modal.Footer>
									<button
										type="submit"
										className={`btn footer-btn ${isSubmitting ? "disabled" : ""}`}
									>
										{isSubmitting ? "Saving..." : "Save"}
									</button>
								</Modal.Footer>
							</Form>
						)}
					</Formik>
				)}
			</Modal>
		</div>
	);
};

export default Dashboard;
